
import { Options, Vue } from "vue-class-component";
import JMTeamcarousel from "@/components/JMTeamcarousel.vue";
import Footer from "@/components/Footer.vue";
import AOS from "aos";

@Options({
  components: {
    JMTeamcarousel,
    Footer,
  },
})
export default class Team extends Vue {
  modal: boolean = false;
  current: number = 0;
  selected: any = {};
  hidemodal: boolean = true;

  mounted() {
    AOS.init()
  }
  updateCurrent(i: any) {
    console.log(i);
    this.current = i;
  }
  goTo(i: number) {
    this.current += i;
    // console.log("alert")
  }
  staffLead: Array<any> = [
    {
      firstName: "Thongchai",
      lastName: " Munpainkit",
      prefix: "Mr.",
      date: "13/05/1987",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
        "Master of Architecture (M.Arch) , Innovative Design of Ecological Architecture (iDEA) @ Faculty of architecture, chulalongkorn university (CU)",
      ],
      position: "Director",
      image: "1.jpg",
    },
    {
      firstName: "Jakkaew",
      lastName: "Na Sampraya",
      prefix: "Mr.",
      date: "30/9/1987",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Senior Architect (Construction)",
      image: "Jakkaew.jpg",
    },
  ];
  staffTeam: Array<any> = [
    {
      firstName: "Tawatchai",
      lastName: "Worawan ",
      prefix: "Mr.",
      date: "04/01/1993",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Architect",
      image: "3.jpg",
      team: "Design",
    },
    {
      firstName: "Patarapong",
      lastName: "Ingjumpoo",
      prefix: "Mr.",
      date: "23/11/1996",
      education: [
        "Bachelor of Architecture (B.Arch) @ School of Architecture and Fine Arts , University of Phayao (UP)",
      ],
      position: "Architect",
      image: "6.jpg",
      team: "Design",
    },
    {
      firstName: "Phubet",
      lastName: "Surin",
      prefix: "Mr.",
      date: "27/01/1996",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture and Design, Rajamangala University of Technology Phra Nakhon (RMUTP)",
      ],
      position: "Architect",
      image: "7.jpg",
      team: "Design",
    },
    {
      firstName: "Nutvadee",
      lastName: "Sanghirun",
      prefix: "Miss.",
      date: "08/11/1995",
      education: [
        "Bachelor of Architecture (B. Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Architect",
      image: "8.jpg",
      team: "Design",
    },
    {
      firstName: "Rattipong",
      lastName: "Teereow",
      prefix: "Mr.",
      date: "24/08/2022",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture and Design, Rajamangala University of Technology Phra Nakhon (RMUTP)",
      ],
      position: "Architect",
      image: "Rattipong.jpg",
      team: "Design",
    },
    {
      firstName: "Kuljira",
      lastName: "Butrsri",
      prefix: "Miss.",
      date: "24/08/2022",
      education: [
        "Education: Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Architect",
      image: "Kuljira.jpg",
      team: "Design",
    },
    {
      firstName: "Patipan",
      lastName: "Kunopas",
      prefix: "Mr.",
      date: "24/08/2022",
      education: [
        "Education: Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Architect",
      image: "Patipan.jpg",
      team: "Design",
    },
  ]
  staffInteriorTeam: Array<any> = [
    {
      firstName: "Thanyaporn",
      lastName: "Waen-ngoen",
      prefix: "Miss.",
      date: "24/08/2022",
      education: [
        "Education: Interior Architecture  @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Interior Designer",
      image: "Thanyaporn.jpg",
      team: "Interior",
    },
    {
      firstName: "Wanwisa",
      lastName: "Horacho",
      prefix: "Miss.",
      date: "24/08/2022",
      education: [
        "Education: Interior Architecture  @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Interior Designer",
      image: "Wanwisa.jpg",
      team: "Interior",
    },
    {
      firstName: "Tinnapop",
      lastName: "Suaykiti",
      prefix: "Mr.",
      date: "24/08/2022",
      education: [
        "Education: Interior Architecture  @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Interior Designer",
      image: "Tinnapop.jpg",
      team: "Interior",
    },
  ]
  staffDetailTeam: Array<any> = [
    {
      firstName: "Thamthawat ",
      lastName: "Timthawat",
      prefix: "Mr.",
      date: "24/08/2022",
      education: [
        "Education: Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)​",
      ],
      position: "Architect (Arch Detail)",
      image: "Thamthawat.jpg",
      team: "Detail",
    },
    {
      firstName: "Wasin",
      lastName: "Pluemsawat",
      prefix: "Mr.",
      date: "24/08/2022",
      education: [
        "Education: Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)​",
      ],
      position: "Architect (Arch Detail)",
      image: "Wasin.jpg",
      team: "Detail",
    },
    {
      firstName: "Thanida",
      lastName: "Thongnoi",
      prefix: "Miss.",
      date: "24/08/2022",
      education: [
        "Education: Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)​​",
      ],
      position: "Architect (Arch Detail)",
      image: "Thanida.jpg",
      team: "Detail",
    },
  ]
  staffConstructionTeam: Array<any> = [
    {
      firstName: "Anek",
      lastName: "Nanthisa",
      prefix: "Mr.",
      date: "24/08/2022",
      education: [
        "B.Arch (Interior Architecture) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)​​",
      ],
      position: "Architect (Construction)",
      image: "Anek.jpg",
      team: "Construction",
    },
    {
      firstName: "Apisit",
      lastName: "Sriwapee",
      prefix: "Mr.",
      date: "24/08/2022",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)​",
      ],
      position: "Architect (Construction)",
      image: "Apisit.jpg",
      team: "Construction",
    },
  ];
  staff: Array<any> = [
    {
      firstName: "Thongchai",
      lastName: " Munpainkit",
      prefix: "Mr.",
      date: "13/05/1987",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
        "Master of Architecture (M.Arch) , Innovative Design of Ecological Architecture (iDEA) @ Faculty of architecture, chulalongkorn university (CU)",
      ],
      position: "Director",
      image: "1.jpg",
    },
    {
      firstName: "Jakkaew",
      lastName: "Na Sampraya",
      prefix: "Mr.",
      date: "30/9/1987",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Senior Architect (Construction)",
      image: "2.jpg",
    },
    {
      firstName: "Tawatchai",
      lastName: "Worawan ",
      prefix: "Mr.",
      date: "04/01/1993",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Architect",
      image: "3.jpg",
    },
    // {
    //   firstName: "Natchapol",
    //   lastName: "Wongdee",
    //   prefix: "Mr.",
    //   date: "12/06/1994",
    //   education: [
    //     "Bachelor of Architecture (B.Arch) @ Faculty of Architecture and Design, Rajamangala University of Technology Phra Nakhon (RMUTP)",
    //   ],
    //   position: "Architect",
    //   image: "4.jpg",
    // },
    // {
    //   firstName: "Nophanan",
    //   lastName: "Sinpru",
    //   prefix: "Miss.",
    //   date: "15/03/1996",
    //   education: [
    //     "Bachelor of Architecture (B.Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
    //   ],
    //   position: "Architect",
    //   image: "5.jpg",
    // },
    {
      firstName: "Patarapong",
      lastName: "Ingjumpoo",
      prefix: "Mr.",
      date: "23/11/1996",
      education: [
        "Bachelor of Architecture (B.Arch) @ School of Architecture and Fine Arts , University of Phayao (UP)",
      ],
      position: "Architect",
      image: "6.jpg",
    },
    {
      firstName: "Phubet",
      lastName: "Surin",
      prefix: "Mr.",
      date: "27/01/1996",
      education: [
        "Bachelor of Architecture (B.Arch) @ Faculty of Architecture and Design, Rajamangala University of Technology Phra Nakhon (RMUTP)",
      ],
      position: "Architect",
      image: "7.jpg",
    },
    {
      firstName: "Nutvadee",
      lastName: "Sanghirun",
      prefix: "Miss.",
      date: "08/11/1995",
      education: [
        "Bachelor of Architecture (B. Arch) @ Faculty of Architecture, Rajamangala University of Technology Thanyaburi (RMUTT)",
      ],
      position: "Architect",
      image: "8.jpg",
    },
  ];
}
