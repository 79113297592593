
import { Options, Vue } from "vue-class-component";
import { ref, watch, reactive } from "vue";

@Options({
  props: {
    navActive: Boolean,
  },
  components: {},
})
export default class JMCarousel extends Vue {
  currentIndex: number = 0;
  navActive!: boolean;

  mounted() {
    watch(() => this.navActive, (current, prev) => {
      this.autoScroll();
    });
    this.autoScroll();
  }
  get active() {
    this.autoScroll();
    return this.navActive;
  }
  autoScroll() {
    if (!this.navActive) {
      let self = this;
      setTimeout(() => {
        if (++this.currentIndex >= 10) this.currentIndex = 0;
        self.changeIndex(this.currentIndex);
        this.autoScroll();
      }, 3000);
    }
  }

  changeIndex(toIndex: number) {
    let container = this.$refs["container"] as HTMLElement;
    let moveTo = toIndex * container.offsetWidth;
    container.scroll({
      top: 0,
      left: moveTo,
      behavior: "smooth",
    });
  }
}
