
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/Footer.vue";
import JMGallery from "@/components/JMGallery.vue";
import axios from "axios";
import Sortable from "sortablejs";

@Options({
  props: {
    detail: Object,
  },
  components: {
    Footer,
    JMGallery,
  },
})
export default class ProgressDetail extends Vue {
  modal: boolean = false;
  current: number = 0;
  selected: any = {};
  images: Array<string> = [];
  imgIndex: number = 0;
  docs: Array<any> = [];
  doc: any = {};
  wasPath: string = "";
  loaded: boolean = false;
  req(item: string) {
    return require(`@/assets${this.doc.image}`);
  }
  updateCurrent(i: any) {
    console.log(i);
    this.current = i;
  }
  goTo(i: number) {
    this.current += i;
    // console.log("alert")
  }
  get sorted(){
    return this.docs.slice().sort((a:any,b:any)=>a.order-b.order)
  }
  updateOrder() {
    let i = 1;
    let promises: Array<Promise<any>> = [];
    this.docs.forEach((el) => {
      promises.push(
        axios.patch("https://haastudiogroup.com/api/v1/project/" + el._id, {
          order: i,
        })
      );
      i++;
    });
    Promise.all(promises).then((responses) => {
      console.log(responses)
      window.location.reload()
    });
  }
  mounted() {
    this.fetchData();
  }

  sortItem(evt: any) {
    let x = evt.oldIndex;
    let y = evt.newIndex;
    var b = this.docs[y];
    this.docs[y] = this.docs[x];
    this.docs[x] = b;
    this.docs[y].order = x + 1;
    this.docs[x].order = y + 1;

    this.$nextTick(() => {
      this.updateOrder();
    });
  }
  fetchData() {
    axios
      .get("https://haastudiogroup.com/api/v1/projects")
      .then((response) => {
        this.docs = response.data;
        this.$forceUpdate()
        let self = this;
        var el = this.$refs.items as HTMLElement;
        if (!this.loaded) {
          var sortable = Sortable.create(el, {
            animation: 150,
            ghostClass: "sortable-ghost",
            chosenClass: "sortable-chosen",
            handle: ".handle",
            onUpdate: self.sortItem,
          });
          this.loaded = true
        }
      });
  }
  deleteData(item: any) {
    var r = confirm("ยืนยันลบข้อมูล " + item.projectName + " ?");
    if (r == true) {
      axios
        .delete("https://haastudiogroup.com/api/v1/project/" + item._id)
        .then((response) => {
          this.fetchData();
        });
    } else {
    }
  }
}
