
import { Options, Vue } from 'vue-class-component';
import  Footer from "@/components/Footer.vue";
import  JMGallery from "@/components/JMGallery.vue";
import axios from 'axios';

@Options({
  props:{
    detail:Object
  },
  components: {
    Footer,
    JMGallery
  },
})
export default class ProgressDetail extends Vue {
  modal: boolean = false;
  current:number = 0;
  selected:any = {}
  image:Array<string> = []
  images:Array<string> = []
  imgIndex:number = 0;
    docs: Array<any> = [];
  doc: any = {
  };
  wasPath: string = "";
  mounted() {
    if(this.$route.params.id!=undefined) this.getData()
  }
  updateData(){
    if(this.$route.params.id == undefined) {
      axios.post('https://haastudiogroup.com/api/v1/project',this.doc).then(response=>{
        console.log("post",response)
        alert("บันทึกข้อมูลสำเร็จแล้ว")
        this.$router.push('/admin/project/list')
      })
    } else {
      axios.patch('https://haastudiogroup.com/api/v1/project/'+this.$route.params.id,this.doc).then(response=>{
      this.getData()
      alert("ปรังปรุงข้อมูลสำเร็จแล้ว")
      this.$router.push('/admin/project/list')
      })
    }
  }
  getData(){
     axios.get('https://haastudiogroup.com/api/v1/project/'+this.$route.params.id).then(response=>{
      this.doc = response.data
    })
  }
}
