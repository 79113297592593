
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
})

export default class Login extends Vue {
  store = useStore();
  username: string = "";
  password: string = "";
  remember: boolean = false;
  users: Array<any> = [
    {
      username: "haa.studiodesign@gmail.com",
      password: "12345",
    },
  ];
  mounted() {
    this.username = this.store.state.remember.username ?? "";
    this.password = this.store.state.remember.password ?? "";
    this.remember = this.store.state.remember.remember ?? false;
  }
  login() {
    let found = this.users.find((el) => el.username == this.username);
    if (found != undefined) {
      if (found.password == this.password) {
        this.store.commit(
          "login",
          this.remember
            ? {
                username: this.username,
                password: this.password,
                remember: this.remember,
              }
            : {}
        );
        alert("ลงชื่อเข้าใช้ถูกต้อง");
        this.$router.push("/admin/project/list");
      } else {
        alert("รหัสผ่านไม่ถูกต้อง");
      }
    } else {
      alert("ไม่พบชื่อผู้ใช้");
    }
    console.log(this.username, this.password);
  }
  logout() {
    this.store.commit("logout");
  }
}
