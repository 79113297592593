
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/Footer.vue";
import JMGallery from "@/components/JMGallery.vue";
import axios from "axios";

@Options({
  props: {
    detail: Object,
  },
  components: {
    Footer,
    JMGallery,
  },
})
export default class ProgressDetail extends Vue {
  modal: boolean = false;
  current: number = 0;
  selected: any = {};
  images: Array<string> = [];
  imgIndex: number = 0;
  docs: Array<any> = [];
  doc: any = {};
  wasPath: string = "";

  encodeGetImage(url:string){
    return encodeURI(this.getImage(url))
  }
  getImage(url:string){
    try {
      if(url.search("http")!==-1) return url
      else if(url===undefined) return require('@/assets/HaaLogo.png')
      else if(url==='') return require('@/assets/HaaLogo.png')
      else if(url.search("uploads")!==-1) return 'https://haastudiogroup.com/api/v1/uploads/'+url.replace('uploads/','')
      else return require('@/assets'+url)
    } catch (error) {
      return "url"
    }
  }
  req(item: string) {
    try {
      return require(`@/assets${this.doc.image}`);
    } catch (error) {
      return item;
    }
  }
  updateCurrent(i: any) {
    console.log(i);
    this.current = i;
  }
  goTo(i: number) {
    this.current += i;
    // console.log("alert")
  }
  lorem = "";

  getImages() {
    this.doc.images.forEach((el: any) => {
      this.images.push(require(`@/assets${el}`));
    });
  }
  mounted() {
    // this.docs =
    // this.docs = require(`@/data/projects.json`);
    // this.doc = this.docs[parseInt(this.$route.params.id as string)]

    axios
      .get(
        "https://haastudiogroup.com/api/v1/project/" + this.$route.params.id
      )
      .then((response) => {
        this.doc = response.data;
      });
    // this.getImages()
    // this.idx = parseInt(this.$route.params.idx);
  }
}
