import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'

import Admin from '../views/Admin.vue'
import Public from '../views/Public.vue'

//Admin
import Login from '../views/admin/Login.vue'
import ProjectForm  from '../views/admin/ProjectForm.vue'
import ProjectList  from '../views/admin/ProjectList.vue'

//Public
import Home from '@/views/public/Home.vue'
import Project from '@/views/public/Project.vue'
import ProjectDetail from '@/views/public/ProjectDetail.vue'
import About from '@/views/public/About.vue'
import Team from '@/views/public/Team.vue'
import Contact from '@/views/public/Contact.vue'
import News from '@/views/public/News.vue'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Public',
    component: Public,
    children:[
      {
        path:'',
        name:"Home 1",
        component:Home
      },
      {
        path:'project',
        name:"Project",
        component:Project
      },
      {
        path:'project/detail',
        name:"ProjectDetail",
        component:ProjectDetail
      },
      {
        path:'project/detail/:id',
        name:"ProjectDetail",
        component:ProjectDetail
      },
      {
        path:'about',
        name:"About",
        component:About
      },
      {
        path:'team',
        name:"Team",
        component:Team
      },
      {
        path:'contact',
        name:"Contact",
        component:Contact
      },
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Public,
    children:[
      {
        path:'',
        name:"Login",
        component:Login
      },
      {
        path:'project/list',
        name:"ProjectList ",
        component:ProjectList
      },
      {
        path:'project/form',
        name:"ProjectForm ",
        component:ProjectForm 
      },
      {
        path:'project/edit/:id',
        name:"Project Edit",
        component:ProjectForm 
      }
    ]
  },
  {
    path: '/',
    name: 'Public',
    component: Public,
    children:[
      {
        path:'',
        name:"Home",
        component:Home
      },
      {
        path:'project',
        name:"Project",
        component:Project
      },
      {
        path:'project/detail',
        name:"ProjectDetail",
        component:ProjectDetail
      },
      {
        path:'project/detail/:id',
        name:"ProjectDetail",
        component:ProjectDetail
      },
      {
        path:'about',
        name:"About",
        component:About
      },
      {
        path:'team',
        name:"Team",
        component:Team
      },
      {
        path:'contact',
        name:"Contact",
        component:Contact
      },
      {
        path:'news',
        name:"News",
        component:News
      },     
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to,from,savedPosition){
    return {
      left:0,top:0,
      behavior:"auto"
    }
  }
})

export default router
