
import { Options, Vue } from "vue-class-component";
import JMTeamcarousel from "@/components/JMTeamcarousel.vue";

@Options({
  props: {
    staff: Array,
    current: Number,
    l: Number,
  },
  components: {
    JMTeamcarousel,
  },
})
export default class Team extends Vue {
  current!: number;
  selected: any = {};
  l!: number;
  goTo(i: number) {}
  emitUp(index: number) {
    if (this.current + index >= 0 && this.current + index < this.l) {
      let final = this.current + index;
      this.$emit("change", final);
      let img = this.$refs.team as HTMLElement;
      let dir = index < 0 ? "fadeInRight" : "fadeInLeft";
      img.classList.add(dir);
      setTimeout(() => {
        img.classList.remove(dir);
      }, 500);
    }
  }
  mounted() {}
}
