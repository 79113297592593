
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/Footer.vue";
import JMFooter from "@/components/JMFooter.vue";
import axios from "axios"
import AOS from "aos";
@Options({
  components: {
    Footer,
    JMFooter,
  },
})
export default class Team extends Vue {
  docs: Array<any> = [];
  doc: any = {};
  wasPath: string = "";
  encodeGetImage(url:string){
    return encodeURI(this.getImage(url))
  }
  getImage(url:string){
    try {
      if(url.search("http")!==-1) return url
      else if(url===undefined) return require('@/assets/HaaLogo.png')
      else if(url==='') return require('@/assets/HaaLogo.png')
      else if(url.search("uploads")!==-1) return 'https://haastudiogroup.com/api/v1/uploads/'+url.replace('uploads/','')
      else return require('@/assets'+url)
    } catch (error) {
      return "url"
    }
  }
  mounted() {
    AOS.init()
    // this.docs = 
    axios.get('https://haastudiogroup.com/api/v1/projects').then(response=>{
      this.docs = response.data
      this.doc = this.docs[parseInt(this.$route.params.id as string)]
      console.log(this.$route.params.id)
    })
    // console.log(this.docs)
    // this.idx = parseInt(this.$route.params.idx);
  }
}
