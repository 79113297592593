
import { Options, Vue } from "vue-class-component";
import  JMTeamcarousel from "@/components/JMTeamcarousel.vue";


@Options({
  props:{
    images:Array,
    index:Number
  },
  components: {
     JMTeamcarousel
  },
})
export default class Team extends Vue {
  docs: Array<any> = [];
  doc: any = {};
  selected:any = {}
  index!:number;
  emitUp(n:number){
    let final = this.index + n
    this.$emit("change",final)
    let img = this.$refs.img as HTMLElement
    let dir = n<0?'fadeInRight':'fadeInLeft'
    img.classList.add(dir)
    setTimeout(()=>{
      img.classList.remove(dir)
    },500)
  }
  mounted(){
  }
}
