
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Navigation extends Vue {
  modal: boolean = false;
  mounted(){
  }
  changeModal(value:boolean){
    const bodyScrollLock = require('body-scroll-lock');
    const disableBodyScroll = bodyScrollLock.disableBodyScroll;
    const enableBodyScroll = bodyScrollLock.enableBodyScroll;
    this.modal = value
    if(value == true){
      disableBodyScroll(".public-container")
    }else{
      enableBodyScroll(".public-container")
    }
  }
}
