import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    isLogin: false,
    remember: {},
  },
  mutations: {
    login: (state, value) => {
      state.isLogin = true
      state.remember = value
    },
    logout: (state, value) => {
      state.isLogin = false
      state.remember = {}
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: "haa",
      paths: ["isLogin", "remember"]
    })
  ]
})