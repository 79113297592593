
import axios from 'axios';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class JMTopNavigation extends Vue {
  docs:Array<any> = []
  mounted(){
    this.fetchData()
  }
  fetchData(){

    axios.get('https://haastudiogroup.com/api/v1/projects').then(response=>{
      this.docs = response.data
      console.log(this.$route.params.id)
    })
  }
}
