import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import JMUpload from '@/components/JMUpload.vue'

import "@popperjs/core"
import "bootstrap"

const app = createApp(App)
  .use(store)
  .use(router)
app.component("JMUpload", JMUpload);

app.mount('#app');
