
import { Options, Vue } from 'vue-class-component';
import JMMaincarousel from "@/components/JMMaincarousel.vue";
import JMCarousel from "@/components/JMCarousel.vue";

@Options({
  props:{
    navActive:Boolean
  },
  components: {
    JMMaincarousel,
    JMCarousel
  },
})
export default class Home extends Vue {
}
